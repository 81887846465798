<template>
  <div id="matchAnalysisContainer" class="main-container " :class="isDesktop ? '' : 'mobile'" ref="matchAnalysis">
    <div class="child-container is-row columns column is-12-desktop is-12-tablet is-12-mobile is-centered">
      <div class="columns is-centered is-bordered-box-shadow-main">
        <div class="next-games-container">
          <div class="next-matches-title is-hidden-tablet" v-if="isDesktop">
            {{ sectionName }}
          </div>
          <DesktopMenuNextMatches
            v-show="isDesktop"
            :size="size"
            :available-games="availableGames"
            :are-games-visible="isFromBet"
            :is-inner-component="isInnerComponent"
            @close="$emit('close')"
            @game-change="onGameChanged"
          />
          <MobileMenuNextMatches
            v-show="!isDesktop"
            :size="size"
            :available-games="availableGames"
            :are-games-visible="isFromBet"
            :is-inner-component="isInnerComponent"
            @close="$emit('close')"
            @game-change="onGameChanged"
          />
          <AdvanceFilter />
          <div class="columns general">
            <div class="column is-12">
              <ViewContainer :size="size" :is-from-bet="isFromBet">
                <template v-slot:content>
                  <template>
                    <KeepAlive>
                      <Component
                        :is="currentComponent"
                        :is-from-bet="isFromBet"
                        :bet-categories="betCategories"
                        :game="game.game_id"
                      />
                    </KeepAlive>
                  </template>
                </template>
              </ViewContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import options from '@/data/next/options';
import { connectSocket } from '@/utils/socketConnection';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import axios from 'axios';
export default {
  name: 'MatchAnalysis',
  components: {
    ItemNextMatches: () => import('@/components/Elements/NextMatches/ItemNextMatches'),
    DesktopMenuNextMatches: () => import('@/components/NavBars/DesktopMenuNextMatches'),
    MobileMenuNextMatches: () => import('@/components/NavBars/MobileMenuNextMatches'),
    AdvanceFilter: () => import('@/components/Elements/NextMatches/AdvanceFilter.vue'),
    ViewContainer: () => import('@/components/Sections/NextMatches/Container'),
    Resumen: () => import('@/components/Sections/NextMatches/Resumen'),
    Barras: () => import('@/components/Sections/NextMatches/Barras'),
    Distribucion: () => import('@/components/Sections/NextMatches/Distribucion'),
    Fuerza: () => import('@/components/Sections/NextMatches/Fuerza'),
    Tabla: () => import('@/components/Sections/NextMatches/Tabla'),
    Cancha: () => import('@/components/Sections/NextMatches/Cancha'),
    TendenciaLive: () => import('@/components/Sections/NextMatches/TendenciaLive'),
    GolstatsLive: () => import('@/components/Sections/NextMatches/GolstatsLive'),
    Xg: () => import('@/components/Sections/NextMatches/Xg.vue'),
    LineupPlayers: () => import('@/components/Sections/LineUp/LineupPlayers'),
    PlayByPlay: () => import('@/components/Sections/PlayByPlay'),
  },
  props: {
    isInnerComponent: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Object,
      default: () => ({
        game_id: 0,
        seasonId: 0,
      }),
    },
    avoidCleanBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewOptions: null,
      size: 769,
      tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      gamesUrl: 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?',
      availableGames: [],
      isFromBet: false,
      defaultFilter: null,
      betCategories: [],
      lastGame: {},
    };
  },
  created() {
    try {
      this.setIsSectionLoading(true);
      this.$store.dispatch('nextGeneral/cleanAllStats');
      window.addEventListener('resize', () => this.setSize());
      this.viewOptions = options.viewOptions;
      this.fillStatistics();
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    this.removeLayoutSpaces();
    this.setSize();
    if (this.getPremiumAccount) connectSocket();
    this.cleanXgStore();
    this.setTeamSelected('Local');
    if (!this.isInnerComponent) {
      setTimeout(() => {
        const mainContainer = document.getElementById('matchAnalysisContainer');
        if (mainContainer) {
          mainContainer.scrollIntoView();
        }
      }, 200);
    }
  },
  methods: {
    ...mapMutations('nextGeneral', [
      'updateIsSocketConnected',
      'setMenuOptionActive',
      'setHome',
      'setAway',
      'setRegularSeasonName',
    ]),
    ...mapMutations('xgGeneral', ['cleanXgStore', 'setTeamSelected']),
    ...mapMutations(['setIsSectionLoading']),
    ...mapActions('nextGeneral', ['cleanStats']),
    setSize() {
      this.size = typeof this.$refs.matchAnalysis === 'undefined' ? this.size : this.$refs.matchAnalysis.clientWidth;
    },
    async generateGamesList() {
      try {
        const tournaments = await this.fetchTournaments(3);
        const mxLeague = tournaments.find(t => t.tournament.id === 1);
        const regularSeason = mxLeague.seasons[0];
        const seasons = [regularSeason.id];
        this.setRegularSeasonName(regularSeason.name);
        regularSeason.related_seasons.forEach(relatedSeason => {
          seasons.push(relatedSeason.id);
        });
        const { seasonsAndGames, seasonsAndTeams } = await this.fetchSeasonsGamesAndTeams(seasons, 3);
        const teamsMap = seasonsAndTeams.reduce((accumulator, seasonAndTeams) => {
          seasonAndTeams.teams.forEach(team => {
            if (!accumulator.has(accumulator.set(team.team_id))) {
              accumulator.set(team.team_id, team);
            }
          });
          return accumulator;
        }, new Map());
        const allGames = seasonsAndGames.reduce((accumulator, seasonAndGames) => {
          seasonAndGames.games.forEach(game => {
            // todo: change to status 1 to production
            if (game.game_status !== 1) {
              game.seasonId = seasonAndGames.season_id;
              game.datetimeMillis = new Date(game.date_time_utc).valueOf();
              game.homeTeam = teamsMap.get(game.home_team);
              game.visitingTeam = teamsMap.get(game.visiting_team);
              game.name = `${game.homeTeam.team_name} vs ${game.visitingTeam.team_name}`;
              accumulator.push(game);
            }
          });
          return accumulator;
        }, []);
        allGames.sort((a, b) => {
          return a.datetimeMillis - b.datetimeMillis;
        });
        this.availableGames = allGames;
      } catch (e) {
        throw new Error(e);
      }
    },
    /**
     * @author Alejandro Martinez
     * @param {number} retries - Times to retry the request
     * @return {Promise<Array>} last5gamesInfo
     */
    async fetchTournaments(retries = 3) {
      // const seasonApi = season == null ? 1325 : this.seasons;
      try {
        let tournamentsUrl = this.tournamentsUrl;
        const { data } = await axios.get(tournamentsUrl);
        return data;
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        }
        throw new Error(e);
      }
    },
    /**
     *
     * @author Alejandro
     * @param {Array<number>} seasons - seasons ids
     * @param {number} retries - Times to retry the request
     * @return {Promise<{seasonsAndGames: *, seasonsAndTeams: *}|*|undefined>}
     */
    async fetchSeasonsGamesAndTeams(seasons, retries) {
      try {
        let gamesUrl = this.gamesUrl;
        seasons.forEach((seasonId, index) => {
          const prefix = index === 0 ? '' : '&';
          gamesUrl += `${prefix}seasons=${seasonId}`;
        });
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        if (retries > 0) {
          return this.fetchSeasonsGamesAndTeams(seasons, retries - 1);
        }
        throw new Error(e);
      }
    },
    removeLayoutSpaces() {
      if (this.$route.query.is_iframe) {
        const body = document.getElementsByTagName('body')[0];
        body.style.paddingTop = '0px';
      }
    },
    async reloadStatistics(isLoginChanged) {
      try {
        this.setIsSectionLoading(true);
        await this.cleanStats();
        if (this.$route.params.gameId || this.game.game_id) {
          await this.fillStatistics(isLoginChanged);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillStatistics(isLoginChanged = false, isNewGameId = false, isNewSeasonId = false) {
      try {
        if (!this.isInnerComponent && this.$route.query.filter) {
          this.defaultFilter = {
            filter: this.$route.query.filter,
          };
          await this.generateGamesList();
          this.isFromBet = true;
        }
        if (this.lastGame.game_id) {
          await this.$store.dispatch('nextGeneral/checkState', {
            gameId: this.lastGame.game_id,
            currentSeasonId: this.lastGame.seasonId,
            isTeamsAnalysis: true,
            defaultFilter: this.defaultFilter,
            gameInfo: this.lastGame,
            isCupGame: !!this.$route.query.isCup || this.game.isCup,
          });
          this.setHome(this.lastGame.homeTeam);
          this.setAway(this.lastGame.visitingTeam);
        } else {
          await this.$store.dispatch('nextGeneral/checkState', {
            gameId: this.isInnerComponent ? this.game.game_id : this.$route.params.gameId,
            currentSeasonId: this.isInnerComponent ? this.game.seasonId : this.$route.query.currentSeason,
            isTeamsAnalysis: true,
            defaultFilter: this.defaultFilter,
            isLoginChanged,
            isCupGame: !!this.$route.query.isCup || this.game.isCup,
            isNewGameId,
            isNewSeasonId,
          });
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async onGameChanged(game) {
      try {
        if (this.lastGame.game_id !== game.game_id) {
          this.lastGame = game;
          await this.$store.dispatch('nextGeneral/cleanStats');
          await this.$store.dispatch('nextGeneral/checkState', {
            gameId: game.game_id,
            currentSeasonId: game.seasonId,
            isTeamsAnalysis: true,
            defaultFilter: this.defaultFilter,
            gameInfo: game,
          });
          this.setHome(game.homeTeam);
          this.setAway(game.visitingTeam);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  beforeDestroy() {
    this.setMenuOptionActive('stats');
    this.$store.dispatch('nextGeneral/exitChannel');
    this.setIsSectionLoading(false);
  },
  watch: {
    '$route.params'(newValue, oldValue) {
      if (newValue.gameId !== oldValue.gameId) {
        this.fillStatistics(
          false,
          true,
          !this.$route.query.currentSeason || this.$route.query.currentSeason !== this.$route.query.currentSeason,
        );
      }
    },
    getPremiumAccount(value) {
      if (this.getPremiumAccount && value) {
        connectSocket();
      }
      if (value && (this.$route.path.toLowerCase().includes('/matches') || this.isInnerComponent)) {
        this.reloadStatistics(true);
      }
    },
    game: {
      deep: true,
      handler(newValue) {
        const analysisGame = { ...newValue };
        analysisGame.visitingTeam = analysisGame.awayTeam;
        delete analysisGame.awayTeam;
        this.onGameChanged(analysisGame);
      },
    },
    '$socket.connected': {
      immediate: true,
      handler(newValue) {
        this.updateIsSocketConnected(newValue);
      },
    },
  },
  computed: {
    ...mapGetters('nextGeneral', ['idAssetSelected', 'gameId', 'menuOptionActive']),
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
    ...mapState('general', ['tournamentSelected']),
    isDesktop() {
      return this.size > 768;
    },
    currentComponent() {
      if (this.menuOptionActive === 'stats') {
        return this.viewOptions
          ? this.viewOptions.find(option => option.id === this.idAssetSelected).component
          : 'Resumen';
      } else if (this.menuOptionActive === 'mxm') {
        return 'PlayByPlay';
      } else if (this.menuOptionActive === 'lineups') {
        return 'LineupPlayers';
      }
      return '';
    },
    sectionName() {
      let currentName = '';
      switch (this.currentComponent) {
        case 'Resumen':
          currentName = 'Resumen';
          break;
        case 'Barras':
          currentName = 'Barras';
          break;
        case 'Distribucion':
          currentName = 'Distribución';
          break;
        case 'Fuerza':
          currentName = 'Fuerza';
          break;
        case 'Tabla':
          currentName = 'Tabla';
          break;
        case 'Cancha':
          currentName = 'Cancha Goles y Orígenes';
          break;
        case 'TendenciaLive':
          currentName = 'Tendencia';
          break;
        case 'GolstatsLive':
          currentName = 'Golstats LIVE';
          break;
        case 'Xg':
          currentName = 'xG';
          break;
      }
      return currentName;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  width: 100%;
  //overflow: no-display !important;
  .is-bordered-box-shadow-main {
    position: relative;
    padding-bottom: 30px;
    margin: 20px 0 0 0;
  }
}
@media screen and (max-width: 768px) {
  .main-container {
    padding: 0 5px 0 5px !important;
  }
}
.next-games-container {
  margin: 0 10px;
  padding: 10px 5px 0 5px;
  min-height: 400px;
  width: 100%;
  @media (min-width: 1100px) {
    width: 900px !important;
  }
  @media (min-width: 900px) {
    width: 800px !important;
  }
}
.container-view.mobile {
  margin: 0px 0.7rem;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
}
.flex-item {
  cursor: pointer;
  font-family: 'Avenir-Demi';
  font-size: 0.75rem;
  width: 50%;
  padding-top: 6%;
  padding-bottom: 6%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  /* border: 1px solid silver;*/
}
.menu-left {
  margin-left: 0.1rem;
  padding: 0 5px 0 5px;
}
.view-container-title {
  font-family: 'Circular-Std';
  background-color: #001d57;
  color: white;
  align-items: center;
  justify-content: center;
}
.match-live-notification {
  font-family: 'Circular-Std';
  font-size: 0.875em;
  padding: 6px 16px;
  width: 65px;
  height: 30px;
  opacity: 0.8;
  border-radius: 6px;
  border: solid 1px #ffffff;
  color: #ffffff;
}
.custom-section-container {
  padding: 0 2rem;
}
/* Olds Styles*/
.card {
  margin: 3rem;
  padding: 3rem;
}
.head {
  background-color: #001445;
  color: aliceblue;
  border-top: 1px solid;
  border-color: black;
}
.main-s {
  background-color: #77ba3f;
  color: aliceblue;
  border-top: 1px solid;
  border-color: black;
}
.main-s h4 {
  color: white;
}
.main {
  border-top: 1px solid;
  border-color: black;
}
.head h1 {
  color: white;
}
.container {
  margin: 3rem;
}
.row {
  background-color: transparent;
  border-color: transparent;
  padding: 2px;
  width: 90%;
}
.panel {
  padding-bottom: 2rem;
}
.advance {
  font-size: 3rem;
}
.icon-down {
  color: crimson;
}
.icon-up {
  color: green;
}
.advanced {
  background-color: #7ed321;
  color: white;
}
.item.a {
  margin-bottom: 0px;
}
.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
}
.tournament {
  padding-right: 10px;
  &__logo {
    height: 26px;
    width: 100%;
    & > img {
      height: 100%;
    }
  }
  & > hr {
    margin: 12px 0 12px;
    width: 100%;
    height: 1px;
    border-width: 0;
    color: #d5cfcf;
    background-color: #d5cfcf;
  }
}
@media screen and (max-width: 768px) {
  .container-view {
    margin: 0 0rem;
    background-color: white;
  }
  .container-view.mobile {
    margin: 0px 0rem;
  }
  .main-container {
    margin: 0px;
    display: flex;
    justify-content: center;
    .is-bordered-box-shadow-main {
      margin: 0;
      padding-bottom: 0;
    }
    .next-matches-title {
      font-family: 'Avenir-Demi';
      font-size: 1.2rem;
      margin: 0 0 10px 0;
      color: #6f7072;
    }
  }
  .next-games-container {
    margin: 0;
    padding: 15px 0 15px 0;
  }
  .child-container {
    padding: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .child-container {
    margin: 5px !important;
    padding: 2px !important;
  }
}
@media screen and (max-width: 400px) {
  .child-container {
    margin: 5px !important;
    padding: 1px !important;
  }
}
@media screen and (max-width: 400px) and (min-width: 300px) {
  .general {
    width: 98vw;
  }
}
</style>
